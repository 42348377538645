<template>
<div id="page" class="withdrawal-index row-start-start" v-loading="loading">
  <div class="wi-left column-center-center">
    <div class="wil-main column-center-center">
      <div class="wi-amount-part row-center-center">
        可提现金额：
        <div class="wiap-icon">￥</div>
        <div class="wiap-amount">{{amount}}</div>
      </div>
      <div class="wim-option">
        <el-form ref="ruleFormRef" class="wimo-form" :model="ruleForm" :rules="rules">
          <el-form-item prop="num" label="提现金额">
            <el-input :disabled="isDisabled" placeholder="请输入提现金额" v-model="ruleForm.num" maxlength="10" />
          </el-form-item>
          <el-form-item prop="verifiCode">
            <el-input :disabled="isDisabled" v-model="ruleForm.verifiCode" placeholder="请输入验证码">
              <template #prepend>
                <el-button @click="send()" :disabled="ruleForm.btnDisabled">{{ruleForm.btnText}}</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item class="option-btn">
            <el-button type="primary" :disabled="ruleForm.submitDisabled" @click="submitForm()">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  <div class="wi-right">
    <div class="wir-title">动账明细</div>
    <el-table :data="table.tableData" v-loading="table.tableLoading">
      <el-table-column prop="dt_datetime" label="动账日期" align="center" />
      <el-table-column prop="txt_bill_type" label="动账类型" align="center" />
      <el-table-column prop="dec_amount" label="金额（元）" align="center">
        <template #default="scope">
          <div :class="scope.row.dec_amount.includes('-') ? 'table-text-extract':'table-text-income'">
            <div :class="scope.row.dec_amount==0?'no-color':''">{{scope.row.dec_amount}}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="table.total" @sizeChange="(e) => {table.limit=e;getList()}" @pageChange="(e) => {table.page=e;getList()}" />
  </div>
</div>
</template>

<script>
// 财务管理 - 提现
import { useStore } from 'vuex';
import { Pagination } from "components";
import { FormInstance, ElMessage } from 'element-plus';
import { reactive, toRefs, ref, computed, onMounted } from "vue";
import { getBalance, sendVerifiCode, submitWithdrawal, getDetailForBalance } from "api/apis.js";
export default {
  components: { Pagination },
  setup() {
    // 金额校验
    const validateNum = (rule, value, callback) => {
      // 判断输入类型
      if (typeof parseInt(value) != "number") {
        callback(new Error("请输入合法的金额！"));
      } else {
        // 判断输入合法性
        const reg = /^\d+(\.\d{1,2})?$/;
        if (!reg.test(value)) {
          callback(new Error("请输入合法的金额！"));
          state.ruleForm.btnDisabled = true;
          // 判断输入金额是否超过账户总金额
        } else if (parseFloat(value) > state.amount) {
          callback(new Error("提现金额不能超过账户金额！"));
          state.ruleForm.btnDisabled = true;
        } else {
          state.ruleForm.btnDisabled = false;
        }
      }
    };

    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      ruleFormRef: ref(FormInstance),
      amount: 0,
      loading: false,
      ruleForm: {
        num: null,
        btnDisabled: true,
        btnText: "获取验证码",
        // 提交按钮可用/禁用判断
        submitDisabled: computed(() => {
          if ((state.ruleForm.num || "").trim() == "") {
            return true;
          } else if ((state.ruleForm.verifiCode || "").trim() == "") {
            return true;
          } else {
            return false;
          }
        }),
        verifiCode: null
      },
      // 校验规则
      rules: {
        num: [
          { required: true, message: "金额不能为空", trigger: ["change", "blur"] },
          { required: true, validator: validateNum, trigger: ["change", "blur"] }
        ],
        verifiCode: [
          { required: true, message: "验证码不能为空", trigger: ["change", "blur"] },
        ]
      },
      timer: null,
      isDisabled: computed(() => store.getters.isDisabled),
      table: {
        tableData: null,
        tableLoading: false,
        total: 0,
        limit: 10,
        page: 1
      }
    });

    // 发送验证码
    const send = () => {
      sendVerifiCode().then(response => {
        if (response.code == 200) {
          state.ruleForm.btnDisabled = true;
          ElMessage.success("发送成功");
          let TIME_CONUT = 60;
          state.ruleForm.btnText = TIME_CONUT + "s";
          if (!state.timer) {
            state.timer = setInterval(function () {
              if (TIME_CONUT > 0 && state.ruleForm.btnText.split("s")[0]) {
                TIME_CONUT--;
                state.ruleForm.btnText = TIME_CONUT + "s";
              } else {
                clearInterval(state.timer);
                state.ruleForm.btnText = "获取验证码";
                state.ruleForm.btnDisabled = false;
                state.timer = null;
              }
            }, 1000);
          }
        } else {
          ElMessage.error(`${response.msg}`);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.ruleForm.btnText = "获取验证码";
      })
    };

    // 提交表单
    const submitForm = () => {
      state.loading = true;
      submitWithdrawal({
        amount: state.ruleForm.num,
        txt_verification_code: state.ruleForm.verifiCode,
        txt_creator: store.getters.userInfo.name || "系统用户",
        dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then(response => {
        if (response.code == 200) {
          state.ruleFormRef.resetFields();
          getACBalance();
          getList();
          ElMessage.success("提现成功");
        } else {
          ElMessage.error(`${response.msg}`);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        // 成功后刷新页面
        getACBalance();
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.tableLoading = true;
      getDetailForBalance({
        page: state.table.page,
        limit: state.table.limit
      }).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data.details;
          state.table.total = response.data.count;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    onMounted(() => {
      getACBalance();
      getList();
    })

    // 获取账户余额
    const getACBalance = () => {
      state.loading = true;
      getBalance().then(response => {
        if (response.code == 200) {
          state.amount = response.data.allAmount || 0;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    return {
      ...toRefs(state),
      send,
      submitForm,
      getACBalance,
      getList
    }
  }
}
</script>

<style lang="scss" scoped>
.withdrawal-index {
  .wi-left {
    width: 50%;
    margin-top: 4%;

    .wil-main {
      width: 400px;
      border-radius: 10px;
      -webkit-box-shadow: 4px 4px 26px rgba(0, 21, 41, 0.35);
      box-shadow: 4px 4px 26px rgba(0, 21, 41, 0.35);

      .wi-amount-part {
        font-size: 20px;
        margin-top: 6px;

        .wiap-amount {
          font-size: 32px;
        }
      }

      .wim-option {
        margin-top: 20px;

        .wimo-form {
          .option-btn {
            /deep/ .el-form-item__content {
              flex-direction: row;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .wi-right {
    width: 50%;
    height: 100%;

    .wir-title {
      font-weight: bold;
      margin-bottom: 6px;
    }

    .el-table {
      width: 100%;
      height: calc(100% - 30px - 40px);
    }
  }
}
</style>
